<template>
    <div class="sub-container">
        <div class="sub-contents">
            <img :src="'/api/main/bnr/image?attaType=01&bannerSeq='+$route.query.bannerSeq"/>
        </div>
    </div>
</template>

<script>
export default {
	data() {
		return {
		}
	},
	beforeMount(){
/*        this.$.httpPost("/api/main/")
            .then(res => {
                this.data = res.data || {};
                for(var i in this.data.banner){
                    var item = this.data.banner[i];
                    if(!Array.isArray(this.banner[item.bannerDivCd])){
                        this.banner[item.bannerDivCd] = [];
                    }
                    this.banner[item.bannerDivCd].push(item);
                }
            }).catch(this.$.httpErrorCommon);*/
	},
}
</script>
